<template>
	<router-view style="padding:12px" />
</template>

<script>
	export default {
		components: {},
		data() {
			return {};
		},
		watch: {},
		methods: {},
	};
</script>

<style lang='scss' scoped>
</style>
